<template>
  <div id="app">
    <div class="sileBar">
      <transition
        enter-active-class="animate__animated animate__fadeInLeft"
        leave-active-class="animate__animated animate__fadeOutLeft"
      >
		   <img
		     src="../../src/pc/assets/nav/nav.png"
		     class="initClick animate__animated animate__fadeInLeft"
		     v-if="isNav"
		     alt
		     @click="showSliderBar"
		   />
        <div class="mask mask_nav" v-show="!isNav">
          <div class="nav">
            <em @click="handleClose"></em>
            <div>
              <div class="nav_in as">
                <div
                  :class="{
                    c0: tt.isC0,
                    c1: tt.isC1,
                    c2: tt.isC2,
                    c3: tt.isC3,
                    c4: tt.isC4,
                  }"
                >
                  <i class="i1"></i>
                  <i class="i2"></i>
                </div>
                <ul id="menu" class="menu">
                  <li data-menuanchor="page1"  @click="goNav" :class="curPage == 1? 'active' : '' ">
                    <a href="#page1">메인</a>
                  </li>
                  <li data-menuanchor="page2" @click="goNav" :class="curPage == 2? 'active' : ''">
                    <a href="#page2">사전 예약</a>
                  </li>
                  <li @click="goNav(3)" :class="curPage == 3? 'active' : ''"><a href="#page3">이벤트</a></li>
                  
                  <li data-menuanchor="page4" @click="goNav" :class="curPage == 4? 'active' : ''">
                    <a href="#page4">게임 특징</a>
                  </li>
                </ul>
				<ul class="icon">
					<li><a href="https://www.youtube.com/watch?v=bK9cmUJjiHc" target="_blank"><img src="./assets/icon1.png" alt=""></a></li>
					<li><a href="https://game.naver.com/lounge/Last_Knights/home" target="_blank"><img src="./assets/icon2.png" alt=""></a></li>
					<li><a href="https://www.facebook.com/LastKnights.KR/" target="_blank"><img src="./assets/icon3.png" alt=""></a></li>
				</ul>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <full-page
      ref="page"
      :options="options"
      id="fullpage"
      @after-load="afterLoad"
    >
      <div class="section banner" id="index">
        <img alt src="./assets/banner/f-bg.png" class="banner-bg" />
        <a href="javascript:;" class="b_btn zin" @click="gotoNextPage">
          <img alt src="./assets/banner/btn1.png"  />
        </a>
      </div>
      <div
        class="section appoint two animate__animated animate__fadeInUp zin"
        id="appoint"
      >
        <img src="./assets/appoint/appoint-bg.jpg" class="appointBg" alt="" />
        
          <form action>
            <div class="radio">
              <div class="radio-left" @click="radioClick_l">
                <a href="javascript:;"  class="gp_unchecked" v-show="gpChecked==false" ></a>
                <a href="javascript:;"  class="gp_checked" v-show="gpChecked==true"></a>
                <img src="./assets/appoint/Google Play.png" alt="">
              </div>
              <div class="radio-right"  @click="radioClick_r">
                <a href="javascript:;" class="store_unchecked" v-show="Checked==false"></a>
                <a href="javascript:;" class="store_checked" v-show="Checked==true"></a>
                <img src="./assets/appoint/App Store.png" alt="">
              </div>
            </div>
			
              <input
                type="tel"
                placeholder="-를 제외한 숫자만 입력하세요"
                maxlength="11"
                class="phone"
                id="phone"
                v-model="form.mobile"
                autocomplete="off"
              />
			  
            <div class="tips">
              <div class="tips_r-left" >
                <a href="javascript:;" class="uncheckedRadio" v-show="tipsChecked == false" @click="tipsChecked = !tipsChecked"></a>
                <a href="javascript:;" class="checkedRadio" v-show="tipsChecked == true" @click="tipsChecked = !tipsChecked"></a>
                <img src="./assets/appoint/argee.png" alt="">
              </div>
              <a href="javascript:;" @click="showReadMe"></a>
            </div>
            <div
              class="btn_appoint"
              v-show="gpChecked || Checked"
              @click="handleSubmit"
            >
              <img src="./assets/appoint/btn.png" alt="" />
            </div>
            <div class="d_btn">
              <a href="https://play.google.com/store/apps/details?id=com.shmf.fsw" target="_blank" class="gpBtn" @click="gpEvent"
                ></a>
              <a href="https://apps.apple.com/kr/app/id1601158229" target="_blank" class="iosBtn" @click="iosEvent"
                >
				</a>
            </div>
            <div class="txt">
              <img src="./assets/appoint/txt.png" alt="" />
            </div>
          </form>
        
      </div>
      <div
        class="section three animate__animated animate__fadeInUp zin"
        id="activity1"
      >
        <div class="t-bg"><img alt="banner" src="./assets/three/three_bg.png" /></div>
        <ul>
          <li class="active">
            <img src="./assets/three/500.png" alt="" v-if="total < 500"/>
            <img src="./assets/three/clear.png" alt="" v-else />
          </li>
          <li>
            <img src="./assets/three/1000.png" alt="" v-if="total < 1000" />
            <img src="./assets/three/clear.png" alt="" v-else />
          </li>
          <li>
            <img src="./assets/three/3000.png" alt="" v-if="total < 3000" />
            <img src="./assets/three/clear.png" alt="" v-else />
          </li>
          <li class="active">
            <img src="./assets/three/5000.png" alt=""  v-if="total < 5000" />
            <img src="./assets/three/clear.png" alt="" v-else />
          </li>
          <li>
            <img src="./assets/three/10,000.png" alt="" v-if="total <= 10000" />
            <img src="./assets/three/clear.png" alt="" v-else />
          </li>
        </ul>
          <a href="https://game.naver.com/lounge/Last_Knights/home" class="three_btn" target="_blank">
		  </a>
      </div>
      <div
        class="section three four animate__animated animate__fadeInUp zin"
        id="character"
      >
        <div class="f-bg"><img alt="banner" src="./assets/five/four_bg.jpg" /></div>
        <div class="four_in as">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide>
              <div class="slidebg">
                <img src="./assets/five/swiper1.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slidebg">
                <img src="./assets/five/swiper2.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slidebg">
                <img src="./assets/five/swiper3.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slidebg">
                <img src="./assets/five/swiper4.png" alt="" />
              </div>
            </swiper-slide>
    			<swiper-slide>
            <div class="slidebg">
    			    <img src="./assets/five/swiper5.png" alt="" />
    			  </div>
    			</swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <div class="line"></div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </full-page>
	<!-- 第二页弹框 -->
	<transition
	  enter-active-class="animate__animated animate__fadeIn"
	  leave-active-class="animate__animated animate__fadeOut"
	>
	  <div class="popMask" v-show="showMask"></div>
	</transition>
	<!-- 请勾选协议 -->
	<transition
	  enter-active-class="animate__animated animate__fadeIn"
	  leave-active-class="animate__animated animate__fadeOut"
	>
	  <div class="popUp pleaseCheck" v-if="pleaseCheck">
	    <img src="./assets/pop/pleaseCheck.png" alt="" />
	    <img src="./assets/pop/x.png" @click="closePop" alt="" />
	  </div>
	</transition>
	<!-- 手机号错误 -->
	<transition
	  enter-active-class="animate__animated animate__fadeIn"
	  leave-active-class="animate__animated animate__fadeOut"
	>
	  <div class="popUp wrongTel" v-if="wrongTel">
	    <img src="./assets/pop/wrongTel.png" alt="" />
	    <img src="./assets/pop/x.png" @click="closePop" alt="" />
	  </div>
	</transition>
	<!-- 手机号重复 -->
	<transition
	  enter-active-class="animate__animated animate__fadeIn"
	  leave-active-class="animate__animated animate__fadeOut"
	>
	  <div class="popUp repeatTel" v-if="repeatTel">
	    <img src="./assets/pop/repeat.png" alt="" />
	    <img src="./assets/pop/x.png" @click="closePop" alt="" />
	  </div>
	</transition>
	<!-- 预约成功 -->
	<transition
	  enter-active-class="animate__animated animate__fadeIn"
	  leave-active-class="animate__animated animate__fadeOut"
	>
	  <div class="popUp appointSuccess" v-if="appointSuccess">
	    <img src="./assets/pop/success.png" alt="" />
	    <img src="./assets/pop/x.png" @click="closePop" alt="" />
	  </div>
	</transition>
	<!-- 阅读协议 -->
	<transition
	  enter-active-class="animate__animated animate__fadeIn"
	  leave-active-class="animate__animated animate__fadeOut"
	>
	  <div class="popUp readProtocol" v-if="readProtocol">
	    <img src="./assets/pop/protocol.png" alt="" />
	    <img src="./assets/pop/x.png" @click="closePop" alt="" />
	  </div>
	</transition>
  </div>
</template>

<script>
// import unchecked from './assets/appoint/radio.png'
// import checked from './assets/appoint/radio_.png'
export default {
  name: "App",
  components: {},
  data() {
    return {
      tt: {
        isC0: true,
        isC1: false,
        isC2: false,
        isC3: false,
      },
      // unchecked: unchecked,
      // checked: checked,
      pageOffsetArr: [],
      options: {
        menu: "#menu",
        anchors: ["page1", "page2", "page3", "page4"],
        afterLoad: this.afterLoad,
      },
      curPage: 1,
      showSection: -1,
      gpChecked: false,
      Checked: false,
      tipsChecked: false,
      isNav: true,
      form: {
        mobile: "",
        channel: "",
      },
      swiperOptions: {
        grabCursor: true,
        slidesPerView: 1,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
      },
      showMask: false,
      pleaseCheck: false,
      wrongTel: false,
      repeatTel: false,
      appointSuccess: false,
      readProtocol: false,
	  total: 0,
    };
  },
  methods: {
	  
    afterLoad(anchorLink ,index ) {
      // console.log( index)
	  
        switch (index.index) {
        case 0:
		  this.curPage =1 
          this.tt.isC0 = true;
          this.tt.isC1 = false;
          this.tt.isC2 = false;
          this.tt.isC3 = false;
          break;
        case 1:
		this.curPage = 2
          this.tt.isC1 = true;
          this.tt.isC0 = false;
          this.tt.isC2 = false;
          this.tt.isC3 = false;
          break;
        case 2:
		this.curPage = 3
          this.tt.isC2 = true;
          this.tt.isC0 = false;
          this.tt.isC1 = false;
          this.tt.isC3 = false;
          break;
        case 3:
		this.curPage = 4
          this.tt.isC3 = true;
          this.tt.isC0 = false;
          this.tt.isC2 = false;
          this.tt.isC1 = false;
          break;
      }
    },
    goNav(page) {
      this.curPage = page;
	  this.isNav = true;
    },
    test(e) {
      var value = document.getElementById("phone").value;
      if (value.length <= 0) {
        document.getElementById("phone").value = "";
      }
    },
    handleSubmit() {
		if(this.gpChecked) {
			this.form.channel = 'Google play'
		}
		if( this.Checked ) {
			this.form.channel = 'Apple store'
		}
		if(this.tipsChecked) {
      console.log(this.validate(this.form.mobile))
			if(this.validate(this.form.mobile)) {
				this.saveTel()
			} else {
				this.wrongTel = true;
				this.showMask = true
			}
		} else {
			this.pleaseCheck = true
			this.showMask = true
		}
	},
  validate(tel){
    let reg = /^\d{11}$/
    return reg.test(tel)
  },
	saveTel() { //保存预约电话
		
		let data = {tel: this.form.mobile, channel: this.form.channel, device:'PC端'}
		this.postHttp(this, data , "/api/v9/checkin", (obj, res) => {
			if(res.code == 200 ) {
				this.appointSuccess = true
				this.showMask = true
				this.form.mobile= ''
			} else if(res.code == 422 ) {
				this.repeatTel = true
				this.showMask = true
				this.form.mobile = ''
			}
		})
	},
	recordEvent(event) { // 记录页面事件
		let datas = {
			event_name: event,
			ver: 9
		}
		this.postHttp(this, datas, "/api/pageEvent",(obj,res) => {
			console.log(res)
		})
	},
	gpEvent() {
		this.recordEvent('click_google_btn')
	},
	iosEvent() {
		this.recordEvent('click_apple_btn')
	},
	getAppointNum() { // 获取预约人数
		this.getHttp(this, '', "/api/v9/getTotal",(obj,res) => {
			if(res.code == 200) {
				this.total = res.data.total
			}
		})
	},
    gotoNextPage() {
      this.$refs.page.api.moveSectionDown();
    },
    handleClose() {
      this.isNav = !this.isNav;
    },
    showSliderBar() {
      this.isNav = false;
    },
    radioClick_l() {
      this.gpChecked = true;
      this.Checked = false;
    },
    radioClick_r() {
      this.Checked = true;
      this.gpChecked = false;
    },
	showReadMe() {
		this.readProtocol = true;
		this.showMask = true
	},
    closePop() {
      this.pleaseCheck = false;
      this.showMask = false;
      this.wrongTel = false;
      this.repeatTel = false;
      this.appointSuccess = false;
      this.readProtocol = false;
    },
  },
  mounted() {
	  this.getAppointNum()
  },
  watch: {
	  
	  
  },
};
</script>

<style lang="stylus">
	html,body{
			height:100%;
		}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
 
}

.ani {
  position: absolute;
}


.swiper-container {
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
    height: 100% !important;
  }
}

.zin {
  z-index: 99 !important;
}

.fixed_appoint {
  width: 3.85rem;
  height: 2.58rem;
  background: url('../pc/assets/fixed.png');
  background-size: 100% 100%;
}

.four {
	display:flex;
	justify-content:center;
	align-items:center;
	width: 100vh;
	height: 100vh;
	.f-bg {
		width: 100%;
		height: 100vh;
		img {
			width: auto;
			height: 100%;
			object-fit: cover;
		}
	}
  .four_in {
    width: 10.5rem;
    height: 100%;
    overflow: hidden;
    top: 3.3rem;
    left: 48%;
    transform: translateX(-50%);

    .swiper-container {
      height: 100%;
    }

    .slidebg {
      width: 10rem;
      height: 6.3333rem;

      img {
        width: 100%;
      }
    }
  }
}

.four .swiper-button-next:after, .four .swiper-rtl .swiper-button-prev:after {
  content: '' !important;
}

.four .swiper-button-prev:after, .four .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}

.four .swiper-button-prev, .four .swiper-rtl .swiper-button-next {
  left: 1.1rem !important;
}

.four .swiper-button-next, .four .swiper-rtl .swiper-button-prev {
  right: 0 !important;
}

.four .swiper-button-prev, .four .swiper-button-next {
  top: 2rem !important;
  width: 0.4rem !important;
  height: 0.8rem !important;
}

.four .swiper-button-prev {
  background: url('../pc/assets/five/prev.png');
  background-size: 100% 100%;
}

.four .swiper-button-next {
  background: url('../pc/assets/five/next.png');
  background-size: 100% 100%;
}

.four .swiper-pagination {
  position: absolute;
  top: 4.5rem;
  left: 4.2rem;
  display: flex;
  align-items: center;
}

.four .line {
  position: absolute;
  top: 4.64rem !important;
  left: 2.6rem !important;
  width: 6.6667rem;
  height: 1px;
  background: #000;
}

.swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 0.5333rem;
}

.swiper-pagination-bullet {
  width: 0.3rem !important;
  height: 0.3rem !important;
  background: url('./assets/five/bullet.png') !important;
  background-size: 100% 100% !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
}

.my-bullet-active {
  width: 0.3rem !important;
  height: 0.3rem !important;
  background: url('./assets/five/bullet_active.png') !important;
  background-size: 100% 100% !important;
}

.txt {
  width: 3.21rem;
  height: 0.73rem;
  font-size: 0.26rem;
  display: block;
  margin: 0.3rem auto;
  font-family: 'sanskr';
  font-weight: bold;
  text-align: center;
}

.mask_nav {
  .nav {
    position: fixed;
    width: 4.8rem;
    height: 10.8rem;
    left: 0;
    top: 50%;
    margin-top: -5.4rem;
    background: url('./assets/nav.png');
    background-size: 100% 100%;

    .nav_in {
      width: 1.32rem;
      height: 3.9rem;
      margin-left: -0.66rem;
      margin-top: -1.65rem;

      i {
        position: absolute;
        display: block;
        width: 0.3733rem;
        height: 0.1733rem;
        top: 0.32rem;
        background: url('./assets/nav_l.png');
        background-size: 100% 100%;

        &.i1 {
          transform: rotate(360deg);
        }

        &.i2 {
          transform: rotate(180deg);
        }
      }

      .c0 {
        .i1 {
          left: -0.5rem;
        }

        .i2 {
          right: -0.5rem;
        }
      }

      .c1 {
        .i1 {
          left: -0.5rem;
          top: 1.2rem;
        }

        .i2 {
          right: -0.5rem;
          top: 1.2rem;
        }
      }

      .c2 {
        .i1 {
          left: -0.5rem;
          top: 2.05rem;
        }

        .i2 {
          right: -0.5rem;
          top: 2.05rem;
        }
      }

      .c3 {
        .i1 {
          left: -0.5rem;
          top: 2.9rem;
        }

        .i2 {
          right: -0.5rem;
          top: 2.9rem;
        }
      }

      ul {
        li.active {
          color: #bb9e6c;

          a {
            color: #bb9e6c;
          }
        }

        li {
          padding: 0.2667rem 0;
          color: #000;
          font-weight: bold;
          font-size: 0.24rem;
          cursor: pointer;

          a {
            text-decoration: none;
            color: #000;
          }
        }

        p {
          margin-top: -0.1333rem;
          font-size: 0.1867rem;
          color: rgba(0, 0, 0, 0.7);
          cursor: pointer;

          a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.7);
          }
        }

        p.active {
          color: #bb9e6c;

          a {
            color: #bb9e6c;
          }
        }
      }
    }
  }

  em {
    display: block;
    position: absolute;
    width: 0.73rem;
    height: 0.67rem;
    top: 1rem;
    left: 5.09rem;
    background: url('./assets/x.png');
    background-size: 100% 100%;
    cursor: pointer;
    transition: all 0.9s;

    &:hover {
      transform: scale(1.2);
    }
  }
  .icon {
  	 margin-top: 0.266666rem;
  	 display:flex;
  	 justify-content:center;
  	 align-items:center;
     li {
       a {
         display: inline-block;
         font-size: 0;
       }
     }
  	 li + li {
  		 margin-left: 10px;
  	 }
  }
}


.sileBar {
  position: fixed;
  top: 7%;
  left: 14%;
  z-index: 3;
  .initClick {
    cursor: pointer;
  }

  img {
    width: 1.3rem;
    transition: all 0.9s;
  }
}

.fixed_appoint {
  right: 0;
  bottom: 10%;
  width: 3.85rem;
  height: 2.58rem;
  cursor: pointer;
}



.fixed_appoint {
  position: fixed;
  z-index: 110;
}

.mask_big {
  .mask_in {
    position: fixed;
    width: 7.34rem;
    height: 5.12rem;
    transform: translate(-50%, -50%);

    a {
      position: absolute;
      display: block;
      width: 1.43rem;
      height: 0.5rem;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 4.45rem;
    }
  }
}

#app {
	
.banner {
	position: relative;
	width: 100vw;
	height: 100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	.banner-bg {
		height: 100%;
		width: auto;
		object-fit: cover;
	}
}

  .b_btn {
    position: absolute;
    width: 3.1rem;
    height: 1.03rem;
    top: 51%;
    right: 63.5%;
    cursor: pointer;
    transition: all 0.3s;
	img {
		
	}
  }

  .three {
    position: relative;
	width: 100vw;
	height: 100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	.t-bg {
		width: auto;
		height: 100vh;
		img {
			height: 100%;
			object-fit: cover;
		}
	}
    ul {
      position: absolute;
      top: 36%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 8rem;
      height: 4.5rem;

      li {
        width: 2rem;
        margin-left: 0.1rem;
      }

      .active {
        margin-left: 0 !important;
      }
    }
	
	.three_btn {
			position: absolute;
		    left: 50%;
		    top: 86%;
			transform: translateX(-50%);
			width: 3.3333rem;
			height: .6133rem;
		    cursor: pointer;
		    background: url('./assets/three/three_btn.png') no-repeat;
		    background-size: 100% 100%;
		    transition: all .3s;
		    overflow: hidden;
	}
  }

  .appoint {
    position: relative;
    width: 100vw;
    height: 100vh;
	display:flex;
	justify-content:center;
	align-items:center;
	.appointBg {
		width: auto;
		height: 100%
		object-fit: cover;
	}
    

      form {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40%;
        left: 46%;
        width: 5.1rem;
        margin-left: -1.5rem;
        
        .radio {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          span {
            font-size: 0.18rem;
            color: #fff;
          }

          .radio-left, .radio-right {
            display: flex;
            align-items: center;
            justify-content: center;
			cursor: pointer;
          }

          .radio-left {
            img {
				margin-left: 10px;
                width: .9rem;
              }
            a {
              width: 0.25rem;
      		  height: 0.25rem;
            }
            .gp_unchecked {
               background: url('./assets/appoint/radio.png') no-repeat;
              background-size: contain;
            }
            .gp_checked {
              background: url('./assets/appoint/radio_.png') no-repeat; 
              background-size: contain;
            }
          }
          .radio-right {
            margin-left: 0.4rem;
            img {
              margin-left: 10px;
                width: .75rem;
              }
            a {
              width: 0.25rem;
      		  height: 0.25rem;
            }
            .store_unchecked  {
               background: url('./assets/appoint/radio.png') no-repeat;
              background-size: contain;
            }
            .store_checked {
              background: url('./assets/appoint/radio_.png') no-repeat; 
              background-size: contain;
            }
           
          } 
        }

        span {
          cursor: pointer;
        }

        input {
		  padding: 12px 0;
          height: 100%;
          border: none;
          font-size: 0.21rem;
          font-family: 'sanskr';
          background: #0d0b0f;
          border: 1px solid #d7bc73;
		  color: #fff;
		  text-indent: 10px;
        }

        .tips {
          left: 0 ;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 4px;
          .tips_r-left {
            display: flex;
            align-items: center;
            padding: 4px 0;
            cursor: pointer;
           img {
              margin-left: 10px;
              width: 80%;
            } 
            .uncheckedRadio, .checkedRadio {
              width: 0.25rem;
              height: 0.25rem;
            }
            .uncheckedRadio {
              background: url('./assets/appoint/radio.png') no-repeat;
              background-size: contain;
            }
            .checkedRadio{
              background: url('./assets/appoint/radio_.png') no-repeat; 
              background-size: contain;
            }
            
          }
     
          a {
            width: 70px;
            height: 20px;
            background: url(./assets/appoint/xy.png) no-repeat center center;
            background-size: contain;
          }
        }

        .btn_appoint {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          cursor: pointer;
          img {
            width: 3.7rem;
          }
        }

        .d_btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: .0667rem;
          width: 100%;
          height:.6rem;
          .gpBtn {
			  width: 2.4rem;
        height: .565rem;
			  background: url(./assets/appoint/gp.png) no-repeat;
			  background-size: 100% 100%;
		  }
		  .iosBtn {
			  width: 2.4rem;
        height: .565rem;
			  background: url(./assets/appoint/ios.png) no-repeat;
			  background-size: 100% 100%;
		  }
        }

        .txt {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 0.2667rem;

          img {
            width: 70%;
          }
        }

        
      }
    
  }

  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 116;

    img:nth-child(1) {
      width: 5.5rem;
    }

    img:nth-child(2) {
      margin-top: -2.7rem;
      margin-left: 0.1rem;
      width: 0.3rem;
      height: 0.3rem;
    }
  }

  .readProtocol {
    img:nth-child(1) {
      width: 6.5rem;
    }

    img:nth-child(2) {
      margin-top: -3.5rem;
      margin-left: 0.1rem;
    }
  }
}
 .popMask {
	 position: fixed;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 right: 0;
	 width: 100%;
	 height: 100%;
	 z-index: 99;
	 background: rgba(0,0,0,.8);
 }
</style>
