import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import http from "./utils/config";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import animated from 'animate.css'
Vue.prototype.$axios = axios
Vue.use(VueAwesomeSwiper)
Vue.use(animated)
import './assets/css/pc.css'
import './assets/css/pc_.css'
Vue.config.productionTip = false

import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);



Vue.prototype.debounce = function (fn, delay) {
	let timer = null
	return function () {
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(fn, delay)
	}
}


// 荷米斯正式服接口地址
var baseUrl = "https://admin.csback.gamemorefun.net";
/*
 * 封装ajax
 * obj : 全局this
 * data : ajax传入后台data数据
 * address : ajax接口地址
 * fn : 成功返回方法  带参数  obj,data  obj:this, data:response
 * */

var querystring = require("querystring");
Vue.prototype.postHttp = function (obj, data, address, fn) {
	http.post(baseUrl + address, querystring.stringify(data)).then(response => {
		fn(obj, response);
	}, response => {

	});
};

Vue.prototype.getHttp = function (obj, data, address, fn) {
	http.get(baseUrl + address, {
		params: data
	}).then(function (response) {
		fn(obj, response);
	}).catch(function (response) {});
};


new Vue({
	render: h => h(App),
}).$mount('#app')